import React from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'

import { GTMClickItemName } from '../../utils/gtm/types/common'

interface Props extends ButtonProps {
  mode: 'primary' | 'secondary' | 'disabled'
  isClosed?: boolean
  'data-gtm-click-item'?: GTMClickItemName
  isShadow?: boolean
}
const commonStyle = {
  fontSize: '14px',
  padding: '8px 16px',
  borderRadius: '100px',
  height: '48px',
}

export const MainButton: React.FC<Props> = ({
  children,
  mode,
  isClosed,
  isShadow = false,
  ...props
}) => {
  // ButtonProps以外のpropsはButtonに渡されるとWarnになったりするので上記のように取り出しておく
  const { isDisabled } = props
  if (mode === 'disabled') {
    return (
      <Button
        // disabledの初期スタイルを定義
        {...commonStyle}
        bg="surface.secondary"
        color="gray.500"
        border="none"
        _active={{ bg: 'surface.secondary' }}
        _hover={{ bg: 'surface.secondary' }}
        cursor="not-allowed"
        // 他はそのまま全て渡す。重複した場合上書きされる。
        {...props}
      >
        {children}
      </Button>
    )
  }
  const bg = mode === 'primary' ? 'brand.blue' : 'surface.primary'
  return (
    <Button
      // modeごとの初期スタイルを定義
      {...commonStyle}
      bg={bg}
      color={mode === 'primary' ? 'text.invert' : 'brand.blue'}
      border={mode === 'secondary' ? '1px solid #25A3DB' : 'none'}
      _active={{ bg: bg }}
      _hover={{ bg: bg }}
      isDisabled={isDisabled || isClosed}
      boxShadow={isShadow ? '0px 4px 16px 0px rgba(0, 0, 0, 0.16)' : 'none'}
      // 他はそのまま全て渡す。重複した場合上書きされる。
      {...props}
    >
      {children}
    </Button>
  )
}
