// 各ページのメタ情報を定義
export const META = {
  home: {
    title:
      '未経験からの正社員転職ならZキャリア｜ 未経験から活躍できる正社員求人が豊富な転職サービス',
    description:
      'Zキャリアは未経験から正社員へ就職・転職したい人のための転職サービスです。未経験OKの多数の求人から、あなたに合った働き方に出会えます。',
  },
  login: {
    title:
      '未経験からの正社員転職ならZキャリア｜ 未経験から活躍できる正社員求人が豊富な転職サービス',
    description:
      'Zキャリアは未経験から正社員へ就職・転職したい人のための転職サービスです。未経験OKの多数の求人から、あなたに合った働き方に出会えます。',
  },
  job: {
    title: '転職・求人検索結果 ｜ 未経験からの正社員転職ならZキャリア',
    description:
      'Zキャリアでは未経験から正社員として活躍できる求人を検索、確認することができます。あなたに合った求人を探して、応募してみましょう！未経験OKの多数の求人から、あなたに合った働き方に出会えます。',
  },
  job_search: {
    title: '求人検索条件変更 ｜ 未経験からの正社員転職ならZキャリア',
    description:
      'Zキャリアでは未経験から正社員として活躍できる求人を検索、確認することができます。あなたに合った求人を探して、応募してみましょう！未経験OKの多数の求人から、あなたに合った働き方に出会えます。',
  },
  job_detail: {
    title: ' ｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  inquiry: {
    title: '求人へのお問い合わせ ｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  inquiry_confirm: {
    title: 'お問い合わせ内容の確認 ｜未経験からの正社員転職ならZキャリア',
    description: '',
  },
  keep_list: {
    title: 'キープした求人｜ 未経験からの正社員転職ならZキャリア',
    description:
      'キープ中の求人がこのページで一覧できます。Zキャリアでは、キープは会員登録前の状態でも利用可能で、会員登録後にはキープした求人はすべて引き継がれます。',
  },
  account: {
    title: 'アカウント情報｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  account_profile: {
    title: 'プロフィールの編集｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  account_desired_conditions: {
    title: '希望条件の編集｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  account_contact: {
    title: '連絡先の編集｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  account_auth_settings: {
    title: 'ログイン方法｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  account_notification: {
    title: '通知設定｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  inquiry_complete: {
    title: 'お問い合わせ完了｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  inquiry_list: {
    title: '問い合わせした求人｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  registration_agent: {
    title: '新規会員登録｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  registration_scout: {
    title: '新規会員登録｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  scout_onboarding: {
    title: '新着スカウト｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  scout_feedback: {
    title: '新着スカウト｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  scout: {
    title: 'スカウト｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  scout_detail: {
    title: '{会社名}からのスカウト｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  scout_inquiry: {
    title: '求人へのお問い合わせ ｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  scout_inquiry_confirm: {
    title: 'お問い合わせ内容の確認 ｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
  terms: {
    title: '利用規約 ｜ 未経験からの正社員転職ならZキャリア',
    description: '',
  },
}
