import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import NextImage, { ImageProps as NextImageProps } from 'next/image'

type PickedNextImageProps = Pick<
  NextImageProps,
  | 'src'
  | 'alt'
  | 'quality'
  | 'priority'
  | 'loading'
  | 'onLoadingComplete'
  | 'onLoad'
  | 'onError'
  | 'placeholder'
  | 'priority'
>

export type ImageProps = PickedNextImageProps &
  Omit<BoxProps, 'position'> & {
    /** 表示される画像の幅 (外側のChakraUIのBoxで利用) */
    width?: number | string
    /** 表示される画像の高さ (外側のChakraUIのBoxで利用) */
    height?: number | string
    /** next/imageに渡すリサイズする画像の幅 */
    nextWidth?: number
    /** next/imageに渡すリサイズする画像の高さ */
    nextHeight?: number
    /** 画像を含むBoxのposition属性（デフォルトは'relative'） */
    position?: React.CSSProperties['position']
    /** 画像のobject-fit属性（デフォルトは'cover'） */
    objectFit?: React.CSSProperties['objectFit']
  }

/**
 * ChakraのImageコンポーネントと互換性を持ちつつ、next/imageを使って画像を表示するコンポーネント
 * NOTE: 元の画像サイズが大きい場合、nextWidth, nextHeightで指定したサイズにリサイズされるため指定してください
 * 実際にレンダリングされた画像サイズを見て、Retinaを考慮して2倍のサイズを指定するくらいが良いと思います
 */
export const ChakraNextImage: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  nextWidth,
  nextHeight,
  position = 'relative',
  objectFit = 'cover',
  className,
  quality,
  loading,
  onLoadingComplete,
  onLoad,
  onError,
  placeholder,
  priority,
  ...rest
}) => {
  return (
    <Box
      position={position}
      width={width}
      height={height}
      overflow="hidden"
      className={className}
      {...rest}
    >
      <NextImage
        src={src}
        alt={alt}
        width={nextWidth || 1400}
        height={nextHeight || 1400}
        quality={quality}
        loading={loading}
        onLoadingComplete={onLoadingComplete}
        onLoad={onLoad}
        onError={onError}
        placeholder={placeholder}
        priority={priority}
        style={{ objectFit, width: '100%', height: '100%' }}
      />
    </Box>
  )
}
