import { Badge, Flex, FormLabel } from '@chakra-ui/react'

interface Props {
  labelText: string
  isRequired: boolean
  isNoBadge?: boolean
}

export const FormLabelWithBadge: React.FC<Props> = (props: Props) => {
  const { labelText, isRequired, isNoBadge } = props

  return (
    <Flex h="18px" alignItems="center" mb={2}>
      <FormLabel mb={0} mr={1} fontSize="14px" fontWeight={700}>
        {labelText}
      </FormLabel>
      {isNoBadge ? (
        <div />
      ) : isRequired ? (
        <Badge variant="solid" color="white" bg="brand.red">
          必須
        </Badge>
      ) : (
        <Badge variant="solid" color="white" bg="#A0AEC0">
          任意
        </Badge>
      )}
    </Flex>
  )
}
