import { CharacterName } from '@/modules/const/Character'

export const GenderOptions = [
  {
    label: '男性',
    value: 1,
  },
  {
    label: '女性',
    value: 2,
  },
  {
    label: '未回答',
    value: 3,
  },
]

export const FinalEducationOptions = [
  {
    label: '中学卒業',
    value: 7,
  },
  {
    label: '高校卒業',
    value: 6,
  },
  {
    label: '高専卒業',
    value: 5,
  },
  {
    label: '専門卒業',
    value: 4,
  },
  {
    label: '短大卒業',
    value: 3,
  },
  {
    label: '大学卒業',
    value: 2,
  },
  {
    label: '大学院修了',
    value: 1,
  },
  {
    label: 'その他',
    value: 8,
  },
]

export const DriverLicenseOptions = [
  {
    label: 'いいえ',
    value: false,
  },
  {
    label: 'はい',
    value: true,
  },
]

export const CurrentEmploymentTypeOptions = [
  {
    label: '正社員',
    value: 1,
  },
  {
    label: '契約社員',
    value: 2,
  },
  {
    label: '業務委託',
    value: 3,
  },
  {
    label: '派遣社員',
    value: 4,
  },
  {
    label: 'パート・アルバイト',
    value: 5,
  },
  {
    label: '無職',
    value: 6,
  },
  {
    label: '学生',
    value: 7,
  },
]

export const DesiredJobStartPeriodsOptions = [
  {
    label: 'できるだけ早く',
    value: 1,
  },
  {
    label: '1〜2ヶ月先',
    value: 2,
  },
  {
    label: '3ヶ月以上先',
    value: 3,
  },
  {
    label: '決まっていない',
    value: 4,
  },
]

export const TalentDesiredJobCategoriesOptions = [
  {
    label: 'まだ決まってない',
    value: 99,
  },
  {
    label: '営業',
    value: 1,
  },
  {
    label: 'ITエンジニア',
    value: 2,
  },
  {
    label: '接客・サービス業',
    value: 3,
  },
  {
    label: '交通・運輸',
    value: 4,
  },
  {
    label: '建設管理',
    value: 5,
  },
  {
    label: '事務・管理',
    value: 6,
  },
  {
    label: '機械系エンジニア',
    value: 7,
  },
  {
    label: 'その他の職種',
    value: 98,
  },
]

export const DesiredWorkEnvironmentOptions = [
  {
    label: '残業が少ない',
    value: 1,
  },
  {
    label: 'しっかり休める',
    value: 2,
  },
  {
    label: '一人で集中できる',
    value: 3,
  },
  {
    label: '研修サポート充実',
    value: 4,
  },
  {
    label: '長く続けられる',
    value: 5,
  },
  {
    label: '成功チャンスあり',
    value: 6,
  },
]

export const DesiredWorkStyleOptions = [
  {
    label: 'みんなで協力する',
    value: 1,
  },
  {
    label: '人と話して楽しむ',
    value: 2,
  },
  {
    label: 'テキパキはたらく',
    value: 3,
  },
  {
    label: 'じっくり考える',
    value: 4,
  },
  {
    label: 'みんなをまとめる',
    value: 5,
  },
  {
    label: '計画を立てる',
    value: 6,
  },
  {
    label: '体を動かす',
    value: 7,
  },
]

export const TalentDesiredJobCategoriesOptionsWithoutNotYetDecided =
  TalentDesiredJobCategoriesOptions.filter((v) => v.value !== 99)

export const registrationFormAgentToValues: {
  key: string // Requestオブジェクトでのkey名を表す
  title: string
  options: {
    label: string
    value: number
  }[]
}[] = [
  {
    key: 'desired_job_start_periods_master_id',
    title: '就業開始までの期間を教えてください',
    options: DesiredJobStartPeriodsOptions,
  },
  {
    key: 'current_employment_type_master_id',
    title: '現在の就業状況を教えてください',
    options: CurrentEmploymentTypeOptions,
  },
  {
    key: 'final_education_master_id',
    title: '最終学歴を教えてください',
    options: FinalEducationOptions,
  },
  {
    key: 'has_driver_license',
    title: '自動車免許はお持ちですか？',
    options: DriverLicenseOptions.map((option) => ({
      label: option.label,
      value: option.value ? 1 : 0,
    })),
  },
  {
    key: 'desired_work_location', // 不使用
    title: '希望勤務地を教えてください',
    options: [],
  },
  {
    key: 'basic_information', // 不使用
    title: '基本情報をご入力ください',
    options: [],
  },
  {
    key: 'contact_information', // 不使用
    title: `最後の質問です
連絡先をご入力ください`,
    options: [],
  },
]

export const TalentWeaknessOptions = [
  {
    label: '人と話す',
    value: 1,
  },
  {
    label: '勉強をする',
    value: 2,
  },
  {
    label: '体を動かす',
    value: 3,
  },
  {
    label: 'コツコツと作業する',
    value: 4,
  },
  {
    label: '計画を立てる',
    value: 5,
  },
  {
    label: '新しいアイディアを考える',
    value: 6,
  },
]

export const TalentAvoidanceOptions = [
  {
    label: 'ずっとオフィスにいる仕事',
    value: 1,
  },
  {
    label: 'ずっと外にいる仕事',
    value: 2,
  },
  {
    label: '競争環境での仕事',
    value: 3,
  },
  {
    label: '目標に追われる仕事',
    value: 4,
  },
  {
    label: 'マニュアル通りの行動',
    value: 5,
  },
  {
    label: '残業が多い仕事',
    value: 6,
  },
  {
    label: '早起きが必要な仕事',
    value: 7,
  },
]

export const TalentAmbitionOptions = [
  {
    label: '給与を上げたい',
    value: 1,
  },
  {
    label: 'スキルアップしたい',
    value: 2,
  },
  {
    label: 'プライベートを大事にしたい',
    value: 3,
  },
  {
    label: '安定した会社で働きたい',
    value: 4,
  },
]

export const TalentPastCompanyOptions = [
  {
    label: '0社',
    value: 0,
  },
  {
    label: '1社',
    value: 1,
  },
  {
    label: '2社',
    value: 2,
  },
  {
    label: '3社',
    value: 3,
  },
  {
    label: '4社',
    value: 4,
  },
  {
    label: '5社以上',
    value: 5,
  },
]

export const TalentYearsOfServiceOptions = [
  {
    label: '1年未満',
    value: 1,
  },
  {
    label: '1年',
    value: 2,
  },
  {
    label: '1年半',
    value: 3,
  },
  {
    label: '2年',
    value: 4,
  },
  {
    label: '2年半',
    value: 5,
  },
  {
    label: '3年',
    value: 6,
  },
  {
    label: '4年',
    value: 7,
  },
  {
    label: '5年',
    value: 8,
  },
  {
    label: '6年',
    value: 9,
  },
  {
    label: '7年',
    value: 10,
  },
  {
    label: '8年',
    value: 11,
  },
  {
    label: '9年',
    value: 12,
  },
  {
    label: '10年以上',
    value: 13,
  },
]

export const registrationFormScoutToValues: {
  key: string
  title: string
  characterMessage: string
  options: {
    label: string
    value: string | number
  }[]
}[] = [
  {
    // 苦手なこと
    key: 'weakness_master_id',
    title: 'この中で、1番苦手なことを教えてください',
    characterMessage: `登録ありがとうございます！
%%name%%さんのプロフィール作成のため、もう少しだけ教えてください`,
    options: TalentWeaknessOptions,
  },
  {
    // やりたくないこと
    key: 'avoidance_master_id',
    title: '1番やりたくないことを教えてください',
    characterMessage: `%%name%%さんの希望に合う求人をお届けできるようにがんばります🚀`,
    options: TalentAvoidanceOptions,
  },
  {
    key: 'talent_ambitions',
    title: '転職・就職で実現したいことを優先度順に1番から3番まで選択してください',
    characterMessage: `自己紹介がまだでした、名前は「${CharacterName}」です🐵`,
    options: TalentAmbitionOptions,
  },
  {
    key: '',
    title: 'あなたに合うポイントをすべて選択してください（3つ以上）',
    characterMessage: `%%name%%さんの希望や好みを知りたいです`,
    options: [...DesiredWorkEnvironmentOptions, ...DesiredWorkStyleOptions],
  },
  {
    key: 'talent_desired_job_categories',
    title: 'ご希望の職種を教えてください（複数選択可）',
    characterMessage: `%%name%%さんはどんなお仕事をご希望ですか？`,
    options: TalentDesiredJobCategoriesOptions,
  },
  {
    key: '',
    title: '働いた会社数と年収について教えてください',
    characterMessage: `そろそろ終わります✏️
もう少しだけ頑張ってください`,
    options: TalentPastCompanyOptions,
  },
  {
    key: 'talent_work_records',
    title: '最後に、最近の職歴について教えてください（最大3社）',
    characterMessage: `%%name%%さん、ありがとうございます！ 最後の質問です`,
    options: [],
  },
]

export const CallAvailableTimeOptions = [
  {
    label: '0:00',
    value: '0',
  },
  {
    label: '1:00',
    value: '1',
  },
  {
    label: '2:00',
    value: '2',
  },
  {
    label: '3:00',
    value: '3',
  },
  {
    label: '4:00',
    value: '4',
  },
  {
    label: '5:00',
    value: '5',
  },
  {
    label: '6:00',
    value: '6',
  },
  {
    label: '7:00',
    value: '7',
  },
  {
    label: '8:00',
    value: '8',
  },
  {
    label: '9:00',
    value: '9',
  },
  {
    label: '10:00',
    value: '10',
  },
  {
    label: '11:00',
    value: '11',
  },
  {
    label: '12:00',
    value: '12',
  },
  {
    label: '13:00',
    value: '13',
  },
  {
    label: '14:00',
    value: '14',
  },
  {
    label: '15:00',
    value: '15',
  },
  {
    label: '16:00',
    value: '16',
  },
  {
    label: '17:00',
    value: '17',
  },
  {
    label: '18:00',
    value: '18',
  },
  {
    label: '19:00',
    value: '19',
  },
  {
    label: '20:00',
    value: '20',
  },
  {
    label: '21:00',
    value: '21',
  },
  {
    label: '22:00',
    value: '22',
  },
  {
    label: '23:00',
    value: '23',
  },
]

export const DeliveryScoutTimeOptions = [
  {
    label: '9時ごろ',
    value: '9',
  },
  {
    label: '12時ごろ',
    value: '12',
  },
  {
    label: '15時ごろ',
    value: '15',
  },
  {
    label: '18時ごろ',
    value: '18',
  },
  {
    label: '21時ごろ',
    value: '21',
  },
]
